import React, { useState } from "react";
import Modal from "../../app/Modal";
import Button from "../../app/Button";
import XButton from "../../app/XButton";
import Box from "@mui/material/Box";
import TextField from "../../app/TextField";
import DataGridModal from "../../app/DataGridModal";
import { useLanguage } from "../../../components/app/LanguageContext";

export default function SearchItemModal(props) {
    const [itemCode, setitemCode] = useState("");

    const [showDataGridModal, setshowDataGridModal] = useState(false);

    const [showsearchItem, setshowsearchItem] = useState(false);
    const {lang} = useLanguage()

    return (
        <>
            <Button
                id="ItemAttributesButton"
                onClick={() => {
                    setshowsearchItem(true);
                }}
            >
                {lang['SEARCH_ITEM']}
            </Button>
            <Modal show={showsearchItem} setshow={setshowsearchItem} title={lang['SEARCH_ITEM']} width="30rem" height="15rem">
                <div className="flex h-full flex-col justify-between p-3">
                    <TextField
                        label={lang['ITEMCODE']}
                        value={itemCode}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                getItemByCode(e.target.value);
                            }
                        }}
                        onChange={(e) => {
                            setitemCode(e.target.value);
                        }}
                        autoFocus
                    />
                    <div className="flex w-full flex-row justify-end">
                        <Button
                            variant="outlined"
                            className="mx-1"
                            id="searchButton"
                            color="primary"
                            onClick={() => {
                                getItemByCode(itemCode);
                            }}
                        >
                            {lang['SEARCH']}
                        </Button>
                    </div>
                </div>
            </Modal>

            {showDataGridModal ? (
                <DataGridModal
                    show={showDataGridModal}
                    setShow={setshowDataGridModal}
                    modalTitle={"Similar Item Code / Original Number / Item Name 1 / Item Name 2 "}
                    searchPhrase={itemCode}
                    quary={`itemcode as 'Item Code',originalnumber as "Original Number",itemname1 as "Item Name",\`set\` as "Set",category as "Category",sale1 as "Sale 1",sale2 as "Sale 2",sale3 as "Sale 3",sale4 as "sale 4" FROM ${localStorage.getItem(
                        "dbname"
                    )}.goods where (itemcode like '%${itemCode}' or itemcode like '${itemCode}%' or itemcode like '%${itemCode}%') or (originalnumber like '%${itemCode}' or originalnumber like '${itemCode}%' or originalnumber like '%${itemCode}%') or (itemname1 like '%${itemCode}' or itemname1 like '${itemCode}%' or itemname1 like '%${itemCode}%') or (itemname2 like '%${itemCode}' or itemname2 like '${itemCode}%' or itemname2 like '%${itemCode}%')`}
                    postdata={props.postdata}
                    onEnterPress={(newval) => {
                        setitemCode(newval);
                    }}
                />
            ) : null}
        </>
    );
    function getItemByCode(itemCode) {
        let url = "goods/get_items/";

        const cbnf = (res) => {
            if (res.data.info === "success") {
                if (res.data.data.goods.length === 1) {
                    props.setselectedRow({ row: { itemcode: itemCode } });
                    props.itemCardModalHandler(true);
                    setshowsearchItem(false);
                } else {
                    setshowDataGridModal(true);
                }
            }
        };
        let fdata = { filters: [` and itemcode = '${itemCode}' `] };
        props.postdata(url, cbnf, fdata, false);
    }
}
