import { TextField as MUITextField } from "@mui/material";
import React, { useState, useEffect } from "react";

export default function DateField({
    value,
    confirmFunc = (e) => {},
    id = "",
    nextFocusId = "",
    onFocus = (e) => {},
    sx,
    inputRef = React.createRef(),
    size = "small",
    label = "Date",
    disabled = false,
}) {
    useEffect(() => {
        if (tempValue) {
            settempValue(dateFormater(value));
        }
    }, [value]);
    const [tempValue, settempValue] = useState(null);

    const onConfirm = (e) => {
        if (e.target.value === dateFormater(value)) return;
        const d = checkDate(e.target.value, value);
        if (!d.error) {
            settempValue(dateFormater(d.date));
            confirmFunc(d.date);
        } else {
            settempValue(dateFormater(value));
        }
    };

    return (
        <MUITextField
            id={id}
            label={label}
            value={tempValue === null ? dateFormater(value) : tempValue}
            onChange={(e) => {
                settempValue(e.target.value);
            }}
            onBlur={(e) => {
                onConfirm(e);
                settempValue(null);
            }}
            onKeyDownCapture={(e) => {
                if (e.key === "Enter") {
                    nextFocusId ? document.getElementById(nextFocusId).focus() : document.activeElement.blur();
                } else if (e.key === "Escape") {
                    settempValue(dateFormater(value));
                }
            }}
            onFocus={(e) => {
                settempValue(dateFormater(value));
                onFocus(e);
                e.target.select();
            }}
            inputRef={inputRef}
            sx={{
                ...sx,
                "& .MuiOutlinedInput-input": { color: "input.text.primary" },
                "& .MuiInputLabel-root": { color: "input.text.secondary" },
            }}
            size={size}
            disabled={disabled}
        />
    );
}
// function checkDate(e, oDate) {
//     let date = [];
//     if (e.split(".").length > 1) {
//         date = e.split(".");
//     } else if (e.split("/").length > 1) {
//         date = e.split("/");
//     } else if (e.split("-").length > 1) {
//         date = e.split("-");
//     } else {
//         date = e.split(" ");
//     }
//     try {
//         const oldDate = oDate.split("-");
//         let finalDate = new Date();
//         console.log(date, oldDate);
//         finalDate.setUTCFullYear(date[2] ?? oldDate[0]);
//         finalDate.setMonth(parseInt(date[1] ?? oldDate[1]) - 1);
//         finalDate.setUTCDate(date[0] ?? oldDate[2]);
//         console.log(finalDate);
//         if (finalDate.toString() === "Invalid Date") {
//             throw new Error("Invalid Date");
//         }
//         return {
//             date: finalDate.toISOString().split("T")[0],
//             error: false,
//         };
//     } catch (e) {
//         console.log(e);
//         return {
//             date: oDate,
//             error: true,
//         };
//     }
// }
function checkDate(e, oDate) {
    let date = e.split(/[-/. ]/); // This line handles splitting by different delimiters
    try {
        const oldDate = oDate.split("-");
        let finalDate = new Date(Date.UTC(oldDate[0], oldDate[1] - 1, oldDate[2])); // Default to old date

        // Check and set the new date components if provided
        if (date.length >= 2) {
            finalDate.setUTCFullYear(date[2] ?? oldDate[0]);
            finalDate.setUTCDate(date[0] ?? oldDate[2]);
            finalDate.setUTCMonth(date[1] ? parseInt(date[1]) - 1 : oldDate[1] - 1);
        }

        if (finalDate.toString() === "Invalid Date") {
            throw new Error("Invalid Date");
        }

        return {
            date: finalDate.toISOString().split("T")[0],
            error: false,
        };
    } catch (e) {
        console.log(e);
        return {
            date: oDate,
            error: true,
        };
    }
}
const dateFormater = (date) => {
    const d = date.split("-");
    let fdate = new Date();
    fdate.setUTCFullYear(parseInt(d[0]));
    fdate.setUTCMonth(parseInt(d[1]) - 1);
    fdate.setUTCDate(parseInt(d[2]));
    fdate.setUTCHours(0);
    console.log(fdate.getUTCMonth(), date);

    return (
        ("0" + fdate.getUTCDate()).slice(-2) +
        "/" +
        ("0" + (fdate.getUTCMonth() !== parseInt(d[1]) - 1 ? fdate.getUTCMonth() : fdate.getUTCMonth() + 1)).slice(-2) +
        "/" +
        fdate.getUTCFullYear()
    );

    // return fdate.toLocaleDateString("en-GB", {
    //     day: "2-digit",
    //     month: "2-digit",
    //     year: "numeric",
    // });
};
