import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tabs from "../../../../../components/app/Tabs";
import OtherBarcodeTab from "../otherBarcode/OtherBarcodeTab";
import PropertiesTab from "../properties/PropertiesTab";
import OthersTab from "../properties/OthersTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className="h-[30vh]"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="h-[30vh] w-[100%]   ">{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function ItemCardTabs(props) {
    const [value, setValue] = React.useState(0);

    return (
        <Box sx={{ width: "100%", height: "40vh", backgroundColor: "modal.secondaryBG" }} className=" rounded ">
            <Tabs
                value={value}
                setvalue={setValue}
                tabsList={[
                    { label: "properties", value: 0 },
                    { label: "Other Barcode", value: 1 },
                    { label: "OTHERS", value: 2 },
                ]}
            >
                {value === 0 && <PropertiesTab ItemProperties={props.ItemProperties} />}
                {value === 1 && (
                    <OtherBarcodeTab
                        otherCodeData={props.otherCodeData}
                        updateItemData={props.updateItemData}
                        postdata={props.postdata}
                        maincode={props.maincode}
                        columns={props.columns}
                        attributes={props.attributes}
                        setOtherCodeData={props.setOtherCodeData}
                        getItemByCode={props.getItemByCode}
                        type={props.type}
                    />
                )}
                {value === 2 && (
                    <OthersTab maincode={props.maincode} postdata={props.postdata} setModalShow={props.setModalShow} />
                )}
            </Tabs>
            {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    sx={{  }}
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                    variant="fullWidth"
                >
                    <Tab sx={{  }}  id="tab1" />
                    <Tab sx={{  }}  />
                    <Tab sx={{  }}  />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
            </TabPanel>
            <TabPanel value={value} index={1}>
            
            </TabPanel>
            <TabPanel value={value} index={2}>
            </TabPanel> */}
        </Box>
    );
}
