import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import XButton from "../app/XButton";
import React from "react";

const dghcn = " border-y-[3px]  border-neutral-400 font-semibold text-[#c7c7c7] ";

export default function DataGridModal(props) {
    const [data, setdata] = useState([]);
    const [cols, setcols] = useState([
        {
            field: "",
            headerClassName: dghcn,
            headerName: "",
            width: 200,
            editable: false,
        },
    ]);
    const [isLoading, setisLoading] = useState(true);
    const [isSelected, setisSelected] = useState(false);

    const dataTableRef = useRef();

    useEffect(() => {
        selectQuary();
    }, []);
    useEffect(() => {
        if (data.length > 0) {
            setTimeout(() => {
                try {
                    dataTableRef.current.children[0].children[1].children[0].children[0].children[0].children[0].focus();
                } catch {
                    dataTableRef.current?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.focus();
                }
            }, 150);
        }
    }, [data, isLoading]);

    return (
        <Modal
            open={props.show}
            onTransitionEnter={() => {}}
            onClose={(e, r) => {
                props.setShow(false);
                try {
                    props.onHideFunc();
                } catch {}
            }}
            id={"DataGridModal"}
            disableEnforceFocus
            disableAutoFocus
            disableRestoreFocus={false}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "75vw",
                    height: "40rem",
                    border: "2px solid #000",
                    boxShadow: 24,
                    borderRadius: "0.8rem",
                    p: 0,
                    backgroundColor: "#27272a",
                }}
            >
                <div className="flex h-[40rem] flex-col justify-between !text-[#c7c7c7] ">
                    <div className="flex w-full flex-row justify-between border-b-2 border-gray-500">
                        <div className="h-[5rem]">
                            <div className=" m-2 text-xl font-semibold">{props.modalTitle}</div>
                            <div className=" mx-4 text-lg font-semibold">For "{props.searchPhrase}"</div>
                        </div>
                        <div>
                            <XButton
                                onClickFunc={() => {
                                    props.setShow(false);
                                    try {
                                        props.onHideFunc();
                                    } catch {}
                                }}
                            />
                        </div>
                    </div>
                    <div className=" h-full p-2">
                        {!isLoading && (
                            <Box sx={{ height: "33.5rem", width: "100%", backgroundColor: "#212529" }}>
                                <DataGrid
                                    sx={{
                                        "& .MuiDataGrid-editInputCell": { color: "#c7c7c7 !important" },
                                        "& .MuiTablePagination-root": { color: "#c7c7c7 !important" },
                                        "& .MuiDataGrid-cell:focus": {
                                            borderWidth: " 1px !important",
                                            borderColor: "blue !important",
                                            outline: "3px auto blue",
                                        },
                                    }}
                                    rows={data}
                                    getRowClassName={(e) => {
                                        if (e.row.line % 2 !== 0) {
                                            return " bg-zinc-800 text-[#c7c7c7]";
                                        }
                                        return " bg-zinc-700 text-[#c7c7c7]";
                                    }}
                                    ref={dataTableRef}
                                    columns={cols}
                                    rowsPerPageOptions={[10, 100, 1000]}
                                    showColumnRightBorder
                                    showCellRightBorder
                                    checkboxSelection={props.selectionOn}
                                    columnHeaderHeight={45}
                                    rowHeight={35}
                                    slotProps={{
                                        cell: { id: "datagridcell", className: " !border-gray-500 " },
                                    }}
                                    showColumnVerticalBorder
                                    showCellVerticalBorder
                                    // onRowSelectionModelChange={(e) => {
                                    //     props.setselectedLines([...e]);
                                    // }}
                                    hideFooter
                                    getRowId={(e) => {
                                        return e[cols[0].field];
                                    }}
                                    onCellDoubleClick={(e) => {
                                        props.onEnterPress(e.row[cols[0].field]);
                                        props.setShow(false);
                                    }}
                                    editMode="cell"
                                    experimentalFeatures={{ newEditingApi: true }}
                                    onCellKeyDown={(e, f) => {
                                        if (f.key === "Enter") {
                                            props.onEnterPress(e.row[cols[0].field]);
                                            props.setShow(false);
                                        }
                                    }}
                                />
                            </Box>
                        )}
                    </div>
                </div>
            </Box>
            {/* <Modal
             show={props.show}
             onHide={() => {
                 props.setShow(false);
                 try{
                     props.onHideFunc()
                 }catch{

                 }
             }}
             size="xl"
             centered
             animation={false}
             enforceFocus={false}
             restoreFocus

         >
             <Modal.Dialog bsPrefix="h-[40rem]" className="h-[40rem] bg-zinc-800 !text-[#c7c7c7]  rounded" >
                 <div className="flex h-[39rem] flex-col ">
                     <div className="h-[5rem]">
                         <div className=" m-2 text-xl font-semibold">{props.modalTitle}</div>
                         <div className=" mx-4 text-lg font-semibold">For "{props.searchPhrase}"</div>
                     </div>
                    
                 </div>
             </Modal.Dialog>
         </Modal> */}
        </Modal>
    );
    function selectQuary() {
        let url = "invoice/custom_select/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setcols((e) => {
                    let temp = [];
                    res.data.data.cols.forEach((element) => {
                        temp.push({
                            field: element,
                            headerClassName: dghcn,
                            headerName: element,
                            width: 200,
                            editable: false,
                        });
                    });
                    return temp;
                });
                setdata(res.data.data.data);
                setisLoading(false);
            }
        };
        let fdata = {
            quary: props.quary,
        };

        props.postdata(url, cbnf, fdata, false);
    }
}
